<template>
  <div>
    <div class="d-block d-md-flex align-items-center justify-content-center justify-content-sm-between my-4 flex-wrap ">
      <h1 class="fw-bold mb-0 text-uppercase me-auto me-md-0">Audio File</h1>
      <div class="d-flex align-items-center ">
        <button class="btn btn-primary rounded-pill py-2 text-uppercase ms-auto me-3" @click="back()">
          <i class="fa fa-arrow-left"></i>
          Back
        </button>
        <button class="btn btn-primary rounded-pill py-2 text-uppercase" @click="next()"
          :disabled="!audio_url || !audio_name">Next <i class="fa fa-arrow-right"></i>
        </button>
      </div>
    </div>
    <div class="card" v-if="!audio_url && !loading && !audio_processing">
      <div class="d-flex flex-column align-items-center py-4" v-if="!uploading">
        <button class="btn btn-primary rounded-pill text-center text-uppercase px-5 mb-3"
          @click="$refs.file_upload.click()">Upload Audio File
        </button>
        <p class="mb-0">Upload MP3 file, with the 256 Kbps option</p>
        <div class="invalid-feedback d-block mt-3" v-if="hasError('audio')">
          <span class="d-block" v-for="(err, key) in getErrors('audio')" :key="'err-' + key">{{ err }}</span>
        </div>
        <input class="form-control d-none" accept="audio/mp3" type="file" @change="select" ref="file_upload">
      </div>
      <div class="row py-5" v-if="uploading">
        <div class="col-md-6 mx-auto">
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="{ width: progress + '%' }"></div>
          </div>
          <div class="d-flex justify-content-between">
            <span>0%</span>
            <span>100%</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading || audio_processing" class="d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="card" :class="{ 'opacity-0': !(waveform_ready && !loading && audio_url) }">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <p v-show="!needTruncate" class="text-secondary mb-0">{{ audio_name ? truncateAudioName : '' }}</p>
          <p v-show="needTruncate" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-trigger="hover focus"
            :data-bs-content="audio_name_formatted ? audio_name_formatted : ''" class="color-secondary ">{{ audio_name ?
              truncateAudioName : '' }}</p>
        </div>
        <div id="waveform"></div>
        <div class="d-flex align-items-center justify-content-center">
          <a class="btn btn-primary rounded-circle btn_play" @click="togglePlay()"><i class="fa " :class="{
            'fa-play': !this.playing,
            'fa-pause': this.playing,
          }"></i> </a>
        </div>
        <div class="d-flex align-items-center justify-content-center my-4">
          <button class="btn btn-outline-secondary rounded-pill px-4 text-uppercase fw-bold py-2" @click="reUpload"><i
              class="fa fa-upload me-2"></i> Upload New File
          </button>
        </div>
      </div>
    </div>


  </div>



</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import WaveSurfer from "wavesurfer.js";
import { Popover } from "bootstrap";


export default {
  name: "AudioUpload",
  components: {},
  props: ['id'],
  mixins: [hasApiValidation],
  watch: {
    chunks(n) {

      if (n.length > 0) {
        this.upload();
        this.is_first = false;
      }
    },
    uploading(n) {
      if (n) {
        this.$noty.success('Upload Start')
      }
    }
  },

  data() {
    return {
      waveform_ready: false,
      file: null,
      chunks: [],
      uploaded: 0,
      uploading: false,
      audio_url: null,
      loading: false,
      wavesurfer: null,
      details_data_url: null,
      audio_name: '',
      playing: false,
      audio_name_str: '',
      is_first: true,
      audio_processing: false
    };
  },
  mounted() {
    this.checkAudio();
    this.audio_name_str = '123qq1231222.mp3';
  },

  methods: {

    async generateWaveformData(url, pixelsPerSecond) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      const rawData = audioBuffer.getChannelData(0); // First channel
      const sampleRate = audioBuffer.sampleRate; // Audio sample rate (e.g., 44100 Hz)
      const totalSeconds = audioBuffer.duration; // Total audio duration in seconds
      const totalSamples = Math.floor(totalSeconds * pixelsPerSecond); // Desired total samples
      const samplesPerPixel = Math.floor(sampleRate / pixelsPerSecond); // Number of samples per pixel

      const waveform = new Array(totalSamples).fill(0);

      for (let i = 0; i < totalSamples; i++) {
        const start = i * samplesPerPixel;
        const end = start + samplesPerPixel;
        const slice = rawData.slice(start, end);

        // Calculate the average (or peak) value for each segment
        waveform[i] = slice.reduce((sum, value) => sum + Math.abs(value), 0) / slice.length;
      }

      return waveform;
    },
    back() {
      this.$router.push({ name: 'EditClass', params: { id: this.id } })
    },
    next() {
      this.$router.push({ name: 'ClassSegments', params: { id: this.id } })
    },
    checkAudio(upload = false) {
      this.loading = true;
      if (upload) {
        this.audio_processing = true;
      }
      this.$http.get('/sessions/' + this.id + '/audio')
        .then(({ data }) => {
          if (!upload) {
            if (data.audio) {

              this.audio_name = data.name;
              this.audio_url = data.audio;

              this.details_data_url = data.details;



              if (this.audio_url) {
                this.drawAudio(this.audio_url);
                // this.generateWaveformData(this.audio_url, 12).then((waveform) => {



                // });

              }
              let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
              popoverTriggerList.map((popoverTriggerEl) => {
                return new Popover(popoverTriggerEl, { content: this.audio_name_formatted })
              })
            }
          }
          else {
            this.audio_processing = false;
            this.checkAudio();
          }

        })
        .finally(() => {
          if (!this.audio_url)
            this.loading = false;
        })
    },

    togglePlay() {
      this.playing ? this.wavesurfer.pause() : this.wavesurfer.play()
    },
    drawAudio(url) {

      if (this.wavesurfer) {
        this.wavesurfer.destroy();
      }
      let wavesurfer = WaveSurfer.create({
        container: '#waveform',
        height: 100,
        controls: true,
        scrollParent: true,
        barHeight: 1,
        barGap: 0,
        barRadius: 1,
        normalize: true,
        // minimap: true,
        backend: 'MediaElement',
        cursorColor: '#CCA438',
        progressColor: '#3f4045',
        waveColor: '#7D8389',
      });
      this.wavesurfer = wavesurfer;
      wavesurfer.on('play', () => {
        this.playing = true;
      });
      wavesurfer.on('pause', () => {
        this.playing = false;
      });
      wavesurfer.zoom(-10)

      this.loading = true;

      wavesurfer.on('loading', (progress) => {

        console.log(`Cargando audio: ${progress}%`);
        if (progress === 100) {
          setTimeout(() => {
            this.loading = false;
          }, 50000);
        }
        // Aquí podrías actualizar una variable, por ejemplo:
        // this.loadingProgress = progress;
      });

      wavesurfer.load(
        // '/157____how.mp3',
        url,
        // details
      );


      wavesurfer.on('ready', () => {
        console.log('ready');
        this.waveform_ready = true;
      })
    },
    select(event) {
      this.file = event.target.files.item(0);
      if (this.file) {
        let f_size = this.file.size / 1024 / 1024;
        let f_ext = this.file.name.split('.').pop();
        let f_type = this.file.type;
        let validName = /^[a-zA-Z0-9_.-]+$/.test(this.file.name);

        if (!validName) {
          this.$noty.error('File name contains invalid characters. Use only letters, numbers, ".", "_" or "-".');
          return;
        }

        if (f_size > 150) {
          this.$noty.error('Audio must not be greater than 150 MBytes');
          return;
        }
        if (f_ext !== 'mp3' || f_type !== 'audio/mpeg') {
          this.$noty.error('Audio file not valid');
          return;
        }
        this.createChunks();
      }
    },
    upload() {
      this.uploading = true;
      this.$http(this.config).then(() => {
        this.chunks.shift();
        if (this.chunks.length === 0) {
          this.file = null;
          this.uploading = false;
          this.$noty.success('Uploaded')
          this.checkAudio(true)
        }
      }).catch((err) => {
        console.log(err, err.response)
        let response = err.response
        if (response && response.data && response.data.message) {
          this.$noty.error(response.data.message);
        }
        this.file = null;
        this.chunks = [];
        this.uploading = false;
        this.is_first = true;
      });
    },
    reUpload() {
      this.audio_url = null;
    },
    createChunks() {
      // Tamaño fijo de 5MB (sin usar query param)
      let limit_mb = 5;
      let size = 1024 * 1024 * limit_mb; // 5MB exactos
      console.log('Tamaño de chunk:', size);

      // Calcular número de chunks
      let chunks = Math.ceil(this.file.size / size);

      // Limpiar chunks anteriores
      //this.chunks = [];

      // Generar chunks
      for (let i = 0; i < chunks; i++) {
        let start = i * size;
        let end = Math.min(start + size, this.file.size);
        let chunk = this.file.slice(start, end, this.file.type);

        // Validar tamaño excepto último chunk
        if (i < chunks - 1 && chunk.size !== size) {
          throw new Error(`Chunk ${i} inválido: ${chunk.size} bytes (debe ser 5MB)`);
        }

        this.chunks.push(chunk);
      }

      console.log('Chunks generados:', this.chunks.length);

      // Validación adicional para archivos pequeños
      if (this.chunks.length === 1 && this.file.size < size) {
        console.warn('Archivo menor a 5MB. Considera subida directa sin multiparte');
      }
      // let limit_mb = this.$route.query.size ?? 5;
      // let size = 1024 * 1024 * limit_mb, chunks = Math.ceil(this.file.size / size);

      // for (let i = 0; i < chunks; i++) {
      //   this.chunks.push(this.file.slice(
      //     i * size, Math.min(i * size + size, this.file.size), this.file.type
      //   ));
      // }
      // console.log(this.chunks.length);
    }
  },

  computed: {
    audio_name_formatted() {
      return this.audio_name ? this.audio_name.split('/').pop() : '';
    },
    truncateAudioName() {
      return this.needTruncate ? this.audio_name_formatted.slice(0, 49) + "…" : this.audio_name_formatted;
    },
    needTruncate() {
      return this.audio_name && this.audio_name_formatted.length > 50
    },

    progress() {
      return this.file ? this.uploaded / (this.uploaded + this.chunks.length) * 100 : null;
    },
    formData() {
      console.log(this.chunks.length);
      let formData = new FormData;
      formData.set('is_first', this.is_first);
      formData.set('is_last', this.chunks.length === 1);
      formData.set('audio', this.chunks[0], `${this.file.name}.part`);
      formData.set('audio_name_str', this.audio_name_str);
      return formData;
    },
    config() {
      return {
        method: 'POST',
        data: this.formData,
        url: '/sessions/' + this.id + '/audio',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        onUploadProgress: () => {
          this.uploaded += 1;
        }
      };
    }
  },
}
</script>

<style scoped>
canvas#audio_canvas {
  width: 800px;
  height: 130px;
  margin: 2rem auto;
}

.progress {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px 3px #0000001f;
}
</style>

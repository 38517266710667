<template>
  <auth-bg>
    <div class="container h-100">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4 mx-auto">
          <div class="card py-4 px-3 rounded-5">
            <div class="card-header bg-transparent border-0 text-center h2 fw-bold text-uppercase">
              Log in
            </div>
            <div class="card-body">
              <p class="text-center">Log in to access your Pilates Project<sub class="h5">&reg;</sub> Instructor Classes</p>
              <ValidationObserver v-slot="{ invalid }">
                <form class="auth-form" @submit.prevent="submit">
                  <ValidationProvider v-slot="v" rules="required|email" name="Email">
                    <text-input v-model="form.email" label="Email" placeholder="Email" type="email"
                                :error="hasError('email',v.errors)"
                                :errors="getErrors('email',v.errors)"
                    >

                      <template #append>
                        <span class="eye-btn"><i class="far fa-envelope"></i></span>
                      </template>
                    </text-input>
                  </ValidationProvider>
                  <ValidationProvider v-slot="v" rules="required" name="Password">
                    <text-input v-model="form.password" label="Password" placeholder="Password"
                                :type="passwordOpen ? 'text': 'password'"
                                :error="hasError('password',v.errors)"
                                :errors="getErrors('password',v.errors)">
                      <template #append>
                        <button class="btn eye-btn" type="button" @click="passwordOpen = !passwordOpen">
                          <i class="far" :class="{'fa-eye': !passwordOpen, 'fa-eye-slash':passwordOpen}"></i>
                        </button>
                      </template>
                    </text-input>
                  </ValidationProvider>
                  <div class="form-check mb-3">
                    <input class="form-check-input" type="checkbox" v-model="form.remember_me" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      Remember me
                    </label>
                  </div>
                  <button class="btn btn-primary d-block mx-auto w-75 rounded-pill text-uppercase" type="submit"
                          :disabled="invalid"><span v-if="!loading">Login</span>
                    <div class="spinner-border" role="status" v-else>
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <a @click.prevent="forgot" class="text-body text-center d-block mt-4 link" href="#">Forgot
                    Password?</a>
                  <a @click.prevent="signUp" class="text-body text-center d-block mt-2 link" href="#">
                    Don't have an account? <span class=" fw-bold">Sign-up</span></a>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-version">
      Version {{ appVersion }} <span v-if="appEnv !== 'PROD'"> - {{ appEnv }}</span>
    </div>
    <div class="bg-white success-modal" :class="{'closed': !successRequest, 'open': successRequest }">
      <h4 class="text-uppercase fw-bolder mb-0">Check Your Email</h4>
    </div>
  </auth-bg>
</template>

<script>
import TextInput from "@/components/form/TextInput";
import hasApiValidation from "@/mixins/hasApiValidation";
import AuthBg from "@/components/AuthBg";


export default {
  name: "Login",
  components: {AuthBg, TextInput},
  mixins: [hasApiValidation],
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION || null,
      appEnv: process.env.VUE_APP_ENV || null, 
      form: {
        email: '',
        password: '',
        remember_me: false,
      },
      passwordOpen: false,
      successRequest: false,
      loading: false,
    }
  },
  mounted() {
    if (this.$route.query.forgot_success) {
      this.openSuccessModal();
    }
  },
  methods: {
    submit() {
      this.setErrors({});
      this.loading = true;
      this.$auth
          .login({
            data: this.form,
            redirect: {name: 'Classes'},
            remember: 'spinning_instructor',
            staySignedIn: true,
            fetchUser: true
          })
          .catch(({response}) => {
            this.$noty.error(response.data.message);
            this.setErrors(response.data.errors);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    signUp() {
      this.$router.push({name: 'SignUp'});
    },
    forgot() {
      this.$router.push({name: 'ForgotPassword'});
    },
    openSuccessModal() {
      this.successRequest = true;
      setTimeout(() => {
        this.successRequest = false;
      }, 5000)
    },
  }
}
</script>

<style scoped lang="scss">
.bg-white.success-modal {
  display: none;
  opacity: 0;
  position: absolute;
  top: 0;
  right: -200px;
  padding: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  &.open {
    display: block;
    opacity: 1;
    right: 0;
  }
}

</style>

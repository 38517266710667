<template>
  <ValidationObserver v-slot="{ invalid, handleSubmit }" v-if="(!this.id || !this.loading) && !this.loading_data ">
    <form @submit.prevent="handleSubmit(submit)" autocomplete="off">
      <div class="d-block d-md-flex align-items-center justify-content-center justify-content-sm-between my-4 flex-wrap">
        <h1 class="fw-bold mb-0 text-uppercase me-auto me-md-0">Class detail</h1>
        <div class="d-flex align-items-center ">
          <button class="btn btn-primary rounded-pill py-2 text-uppercase ms-auto me-3 text-nowrap"
                  @click="$router.push({name: 'Classes'})">
            <i class="fa fa-arrow-left"></i>
            Back
          </button>
          <button class="btn btn-primary rounded-pill py-2 text-uppercase text-nowrap" type="submit" :disabled="invalid">Next <i
            class="fa fa-arrow-right"></i></button>
        </div>
      </div>
      <div class="card rounded mt-2 border-0 shadow">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <ValidationProvider v-slot="v" rules="required|min:5|max:255" name="Title">
                <text-input
                    label="Title"
                    v-model="new_class.name"
                    :error="hasError('name',v.errors)"
                    :errors="getErrors('name', v.errors)"
                    required
                ></text-input>
              </ValidationProvider>
              <ValidationProvider v-slot="v" rules="required" name="Instructors">
                <form-group :error="hasError('instructors',v.errors)" :errors="getErrors('instructors', v.errors)">
                  <label class="fw-medium">Instructors</label>
                  <multiselect v-model="new_class.instructors" :options="data.instructors"
                               placeholder="Instructors"
                               :multiple="true"
                               track-by="id"
                               label="name"
                  >
                    <template slot="option" slot-scope="props">
                      <div class="custom_multi_check me-2">
                      </div>
                      {{ props.option.name }}
                      <!--                  <img class="option__image" :src="props.option.img" alt="No Man’s Sky">-->
                      <!--                  <div class="option__desc"><span class="option__title">{{ props.option.title }}</span>-->
                      <!--                    <span class="option__small">{{ props.option.desc }}</span>-->
                      <!--                  </div>-->
                    </template>
                  </multiselect>
                </form-group>
              </ValidationProvider>
              <ValidationProvider v-slot="v" rules="required|min:10" name="Description">
                <form-group :error="hasError('description', v.errors)" :errors="getErrors('description', v.errors)">
                  <label class="fw-medium">Description <span class="px-2">*</span></label>
                  <editor v-model="new_class.description" />
                </form-group>
              </ValidationProvider>
            </div>
            <div class="col-lg-6">
              <ValidationProvider v-slot="v" rules="required" name="Studio">
                <form-group :error="hasError('studio.id', v.errors)" :errors="getErrors('studio.id', v.errors)">
                  <label class="fw-medium">Studio <span class="px-2">*</span></label>
                  <multiselect v-model="new_class.studio" :options="data.studios"
                               placeholder="Search "
                               track-by="id"
                               label="name"
                  >
                  </multiselect>
                </form-group>
              </ValidationProvider>

              <div class="row">
                <div class="col-lg-6">
                  <form-group
                      popover-content="Only for Admins">
                    <label class="fw-medium">Release Date</label>
                    <vc-date-picker v-model="new_class.release_date" mode="date" :masks="masks">
                      <template v-slot>
                        <div class="input-group">
                          <input
                            class="form-control"
                            :value="formattedReleaseDate"
                            readonly
                          />
                          <span class="eye-btn"><i class="far fa-calendar"></i></span>
                        </div>
                      </template>
                    </vc-date-picker>
                  </form-group>
                </div>
                <div class="col-lg-6">
                  <form-group
                      popoverContent="Only for Admins">
                    <label class="fw-medium">Release Time</label>
                    <vc-date-picker v-model="new_class.release_date" mode="time" :is24hr="is24Hour" :masks="masks"
                                    class="hide_date">
                        <template v-slot>
                        <div class="input-group">
                          <input
                              class="form-control"
                              :value="formattedReleaseTime"
                              readonly
                          />
                          <span class="eye-btn"><i class="far fa-clock"></i></span>
                        </div>
                      </template>
                    </vc-date-picker>
                  </form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <form-group :error="hasError('production_date')" :errors="getErrors('production_date')">
                    <label class="fw-medium">Production Date</label>
                    <vc-date-picker v-model="new_class.production_date" mode="date"  :is24hr="is24Hour" :masks="masks">
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="input-group">
                          <input
                              class="form-control"
                              :value="formattedProductionDate"
                              v-on="inputEvents"
                          />
                          <span class="eye-btn"><i class="far fa-calendar"></i></span>
                        </div>
                      </template>
                    </vc-date-picker>
                  </form-group>
                </div>
                <div class="col-lg-6">
                  <form-group>
                    <label class="fw-medium">Production Time</label>
                    <vc-date-picker v-model="new_class.production_date" mode="time" :is24hr="is24Hour" :masks="masks"
                                    class="hide_date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="input-group">
                          <input
                              class="form-control"
                              :value="formattedProductionTime"
                              v-on="inputEvents"
                          />
                          <span class="eye-btn"><i class="far fa-clock"></i></span>
                        </div>
                      </template>
                    </vc-date-picker>
                  </form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6">
                  <text-input 
                    :value="$formatDate(new_class.updated_at ? new_class.updated_at : new Date())"
                    :label="'Last Updated'" readonly/>
                </div>
                <div class="col-lg-6">
                  <form-group :error="hasError('class_type.id')" :errors="getErrors('class_type.id')">
                    <label class="fw-medium">Type</label>
                    <multiselect v-model="new_class.class_type" :options="data.class_types"
                                 placeholder="Type"
                                 track-by="id"
                                 label="name"
                                 disabled
                    >
                    </multiselect>
                  </form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">

                  <ValidationProvider v-slot="v" rules="required" name="Length">
                    <form-group :error="hasError('length.id', v.errors)" :errors="getErrors('length.id', v.errors)">
                      <label class="fw-medium">Length <span class="px-2">*</span></label>
                      <multiselect v-model="new_class.length" :options="data.length"
                                   placeholder="Length"
                                   track-by="id"
                                   label="name"
                      >
                      </multiselect>
                    </form-group>
                  </ValidationProvider>
                </div>
                <div class="col-lg-6">
                  <ValidationProvider v-slot="v" rules="required" name="Language">
                    <form-group :error="hasError('language.id', v.errors)" :errors="getErrors('language.id', v.errors)">
                      <label class="fw-medium">Language <span class="px-2">*</span></label>
                      <multiselect v-model="new_class.language" :options="data.languages"
                                   placeholder="Language"
                                   track-by="id"
                                   label="name"
                      >
                      </multiselect>
                    </form-group>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <form-group :error="hasError('categories')" :errors="getErrors('categories')">
                    <label class="fw-medium">Categories</label>
                    <multiselect v-model="new_class.categories" :options="data.categories"
                                 placeholder="Categories "
                                 :multiple="true"
                                 track-by="id"
                                 label="name"
                    >
                    </multiselect>
                  </form-group>
                </div>
                <div class="col-lg-6">
                  <form-group :error="hasError('tags')" :errors="getErrors('tags')">
                    <label class="fw-medium">Tags</label>
                    <multiselect v-model="new_class.tags" :options="dataForTagSelect(data.tags)"
                                 placeholder="Tags"
                                 :multiple="true"
                                 :taggable="true"
                                 @tag="addTag"
                    >
                    </multiselect>
                  </form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <text-input v-model="new_class.vimeo_id" label="Vimeo ID"
                              :error="hasError('vimeo_id')"
                              :errors="getErrors('vimeo_id')"
                              disabled
                  ></text-input>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import TextInput from "@/components/form/TextInput";
import Multiselect from 'vue-multiselect';
import FormGroup from "@/components/form/FormGroup";
import EditorJodit from "../../components/EditorJodit.vue";


export default {
  name: "AddClass",
  components: {
    FormGroup, TextInput,
    Multiselect,
    'editor': EditorJodit,
  },
  mixins: [hasApiValidation],
  props: ['id'],
  data() {
    return {
      roles: [],
      new_class: {
        name: '',
        instructors: [],
        tags: [],
        categories: [],
        class_type: null,
        length: null,
        language: null,
        production_date: null,
        studio: null,
        release_date: null,
        updated_at: null,
        vimeo_id: '',
      },
      masks: {
        inputDateTime24hr: 'YYYY-MM-DD HH:mm',
        inputDate: 'YYYY-MM-DD',
        inputTime24hr: 'HH:mm',
      },
      data: {
        instructors: []
      },
      loading_data: true,
      loading: false,
    }
  },
  mounted() {
    if (this.id) {
      this.loading = true;
      this.fetchClass(this.id);
    }
    this.getData();
  },
  methods: {
    fetchClass(id) {
      this.$http.get('/sessions/' + id).then(({data}) => {
        let obj = data.data;
        obj.production_date = obj.production_date ? this.$moment(obj.production_date).toDate() : null;
        Object.assign(this.new_class, obj)
      }).finally(() => {
        this.loading = false;
      })
    },
    getData() {
      this.loading_data = true;
      this.$http.get('/sessions/data').then(({data}) => {
        this.data = data
      }).finally(() => {
        this.loading_data = false;
      })
    },
    addTag(newTag) {
      this.data.tags.push(newTag)
      this.new_class.tags.push(newTag)
    },
    dataForTagSelect(data) {
      return data.map(item => {
        return item.name
      })
    },
    submit() {
      let data = Object.assign({}, this.new_class);
      let method = this.id ? 'update' : 'save'
      this[method](data)
          .then(({data}) => {
            this.setErrors({})
            this.$router.push({name: 'ClassAudio', params: {id: data.data.id}}).then(() => {
              this.$noty.success("Updated")
            })
          })
          .catch(({response}) => {
            this.$noty.error(response.data.message)
            this.setErrors(response.data.errors)
          })
    },
    save(data) {
      return this.$http.post('/sessions/', data)
    },
    update(data) {
      return this.$http.put('/sessions/' + this.id, data)
    }
  },
  computed: {
    formattedReleaseDate() {
      return this.$formatDate(this.new_class.release_date);
    },
    formattedReleaseTime() {
      return this.$formatTime(this.new_class.release_date);
    },
    formattedProductionDate() {
      return this.$formatDate(this.new_class.production_date);
    },
    formattedProductionTime() {
      return this.$formatTime(this.new_class.production_date);
    },
    is24Hour() {
      return this.$is24Hours();
    },
  }
}
</script>

<style scoped>
div >>> .form-group {
  margin-bottom: 2rem !important;
}
</style>
